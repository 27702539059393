import { Observer } from 'mobx-react-lite';
import React from 'react';
import { getContrastColorHex } from '../../utils/colors.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import './Notice.scss';

type NoticeProps = {
  backgroundColor?: string
  foregroundColor?: string
  fontSize?: string
}

const Notice: React.FC<NoticeProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get style() {
      return {
        backgroundColor: p.backgroundColor,
        color: p.foregroundColor ?? (p.backgroundColor ? getContrastColorHex(p.backgroundColor) : undefined),
        fontSize: p.fontSize,
      }
    }
  }))
  return <Observer children={() => (
    <div className="Notice" style={s.style}>
      <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M48 25.5C48 37.9264 37.9264 48 25.5 48C13.0736 48 3 37.9264 3 25.5C3 13.0736 13.0736 3 25.5 3C37.9264 3 48 13.0736 48 25.5ZM51 25.5C51 39.5833 39.5833 51 25.5 51C11.4167 51 0 39.5833 0 25.5C0 11.4167 11.4167 0 25.5 0C39.5833 0 51 11.4167 51 25.5ZM23.009 14.834C23.633 15.406 24.4 15.692 25.31 15.692C26.246 15.692 27.013 15.406 27.611 14.834C28.235 14.236 28.547 13.508 28.547 12.65C28.547 11.792 28.235 11.077 27.611 10.505C27.013 9.933 26.246 9.647 25.31 9.647C24.4 9.647 23.633 9.933 23.009 10.505C22.411 11.077 22.112 11.792 22.112 12.65C22.112 13.508 22.411 14.236 23.009 14.834ZM17.549 34.412V38H33.851V34.412H27.767V23.297C27.767 21.685 27.39 20.515 26.636 19.787C25.908 19.033 24.738 18.656 23.126 18.656H18.095V22.244H22.385C23.217 22.244 23.633 22.673 23.633 23.531V34.412H17.549Z" fill="currentColor" />
      </svg>
      {p.children}
    </div>
  )} />
}

export default Notice;