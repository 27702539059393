import React from 'react';
import Notice from '../Notice/Notice';
import './WIPNotice.scss';

type WIPNoticeProps = {}

const WIPNotice: React.FC<WIPNoticeProps> = props => {
  return <Notice>
    <p>This section is currently under construction.</p>
  </Notice>
}

export default WIPNotice;