import { flow, toJS } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useAppContext } from '../../controllers/app.controller';
import { isProduction } from '../../env';
import { makePostRequest, PostRequest } from '../../utils/api.utils';
import { ColorPalette } from '../../utils/colors.utils';
import { asyncGetRecaptchaToken } from '../../utils/loadRecaptcha.utils';
import { log, reportError } from '../../utils/loggers.utils';
import { useStore } from '../../utils/mobx.utils';
import BaseCheckbox from '../BaseCheckbox/BaseCheckbox';
import BaseInput from '../BaseInput/BaseInput';
import BaseTextArea from '../BaseTextArea/BaseTextArea';
import CTAButton from '../CTAButton/CTAButton';
import Notice from '../Notice/Notice';
import PageSection from '../PageSection/PageSection';
import Redirect from '../Redirect/Redirect';
import WIPNotice from '../WIPNotice/WIPNotice';
import './ContactForm.scss';

type FormSubmissionResponse = {
  success: boolean,
}

type ContactFormProps = {
  preSelectedTopics?: string[],
}

const ContactForm: React.FC<ContactFormProps> = props => {

  const { UI } = useAppContext();

  const s = useStore(() => ({
    form: {
      name: '',
      email: '',
      phone: '',
      company: '',
      website: '',
      message: '',
      city: '',
      topics: props.preSelectedTopics ?? [],
      hasAgreedToTerms: false,
      recaptchaToken: '',
    },

    get emailBody() {
      let value = '';
      if (s.form.name) { value += `Name: ${s.form.name}`; value += '\n'; }
      if (s.form.email) { value += `Email: ${s.form.email}`; value += '\n'; }
      if (s.form.phone) { value += `Phone: ${s.form.phone}`; value += '\n'; }
      if (s.form.company) { value += `Company: ${s.form.company}`; value += '\n'; }
      if (s.form.website) { value += `Website: ${s.form.website}`; value += '\n'; }
      if (s.form.city) { value += `City: ${s.form.city}`; value += '\n'; }
      if (s.form.topics.length > 0) { value += `Topics: ${s.form.topics.join(', ')}`; value += '\n'; }
      if (value) value += '\n';
      value += 'Message: \n';
      value += s.form.message;
      value += '\n';
      return encodeURIComponent(value);
    },

    postRequest: null as Nullable<PostRequest<FormSubmissionResponse>>,
    get formIsValid(): boolean {
      return !!(s.form.name && s.form.email && s.form.message);
    },

    handleFormChange: () => {
    },

    overrideState: undefined,
    // overrideState: isProduction ? 'disabled' : undefined,
    recaptchaError: null as Error | null,
    isFetchingRecaptchaToken: false,
    get isSubmitting() {
      return s.isFetchingRecaptchaToken || s.postRequest?.isPending
    },
    get succeeded() {
      return s.postRequest?.status === 200;
    }
  }))

  const handleSubmit = flow(function* (e?: React.FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    if (!s.form.name) { UI.prompt('Please enter your name.'); return; }
    if (!s.form.email) { UI.prompt('Please enter your email.'); return; }
    if (!s.form.email.includes('@') || !s.form.email.includes('.')) { UI.prompt(`Your email doesn't seem to be valid, please double check and try again.`); return; }
    if (!s.form.message) { UI.prompt('Please enter your message before submitting.'); return; }
    if (!s.form.hasAgreedToTerms) { UI.prompt('You need to agree to our Data Protection Policy before continuing.'); return; }
    const url = 'contact';
    try {
      s.isFetchingRecaptchaToken = true;
      s.form.recaptchaToken = yield asyncGetRecaptchaToken('contact_form_submission', UI);
    } catch(e) {
      s.recaptchaError = e as any;
      reportError(e as any);
      return;
    } finally {
      s.isFetchingRecaptchaToken = false;
    }
    log(s.form.recaptchaToken);
    const payload = toJS(s.form);
    log(payload);
    s.postRequest = makePostRequest<FormSubmissionResponse>(url, payload);
    log(s.postRequest);
  })

  return <Observer children={() => (
    <div className="ContactForm" data-state={s.overrideState || (s.isSubmitting ? 'pending' : s.postRequest?.state)}>

      <PageSection id="ContactFormSection" className="ContactFormSection">

        {/* <StandardTextBlock animateTextEnter={{ delay: 100 }}>
          <Widowless>Interested in working with us?</Widowless>
          <Widowless>We are always ready to help.</Widowless>
          <p>Write us an email: <a href="mailto:info@axondivision.com?subject=Service Enquiry" title="Write us an email">info@axondivision.com</a></p>
        </StandardTextBlock> */}

        { isProduction && s.overrideState === 'disabled' && <WIPNotice /> }

        <form className="ContactFormForm" onChange={s.handleFormChange} onSubmit={handleSubmit} noValidate>
          <div className="ContactFormPersonalInfo">
            <BaseInput form={s.form} field="name" Label="Your Name" type="text" autoComplete="name" required />
            <BaseInput form={s.form} field="email" Label="Email" type="email" autoComplete="email" required />
            {/* {UI.cssFeatures.grid && UI.viewport.width >= 1366 && <div className="ContactFormPersonalInfoLargeDesktopGridFiller"/> } */}
            <BaseInput form={s.form} field="phone" Label="Phone" type="tel" autoComplete="phone" />
            <BaseInput form={s.form} field="company" Label="Company" type="text" autoComplete="company" />
            {/* <BaseInput form={s.form} field="website" Label="Website" type="url" autoComplete="website" /> */}
            {/* <BaseInput form={s.form} field="city" Label="City" type="text" autoComplete="city" /> */}
          </div>

          {/* <h3>What are you interested in?</h3>

          <div className="ContactFormServiceSelector">
            <ContactFormTopicSelectorTab
              form={s.form}
              field="topics"
              value="apps"
              Label="Apps"
              Description="Bespoke App Design and Development"
            />
            <ContactFormTopicSelectorTab
              form={s.form}
              field="topics"
              value="websites"
              Label="Websites"
              Description="Bespoke Website Design and Development"
            />
            <ContactFormTopicSelectorTab
              form={s.form}
              field="topics"
              value="design"
              Label="Design"
              Description={<>
                <p>Branding</p>
                <p>Animations</p>
                <p>Illustrations</p>
                <p>Advertisements</p>
                <p>Packaging</p>
                <p>Print Design</p>
              </>}
            />
            <ContactFormTopicSelectorTab
              form={s.form}
              field="topics"
              value="general"
              Label="Just a Chat"
              Description=""
            />
          </div>
   */}
          <footer className="ContactFormFooter">

            <div>

              <BaseTextArea form={s.form} field="message" rows={4} Label={<h3>Write a Message <em>* Required</em></h3>} />

              <BaseCheckbox form={s.form} field="hasAgreedToTerms" Label={
                <>I have read and agree to Trackster's <a href="/data-protection-policy" target="_blank" title="Data Protection Policy">Data&nbsp;Protection&nbsp;Policy&nbsp;<sup>↗</sup></a>.</>
              } />

              <Notice backgroundColor={ColorPalette.white} foregroundColor={ColorPalette.gray}>
                <p>This site is protected by <a href="https://www.google.com/recaptcha/about/" target="_blank" rel="noreferrer" title="About Google reCAPTCHA">reCAPTCHA</a> and the Google <a target="_blank" rel="noreferrer" href="https://policies.google.com/privacy" title="Google reCAPTCHA Privacy Policy">Privacy Policy</a> and <a target="_blank" rel="nofererrer" href="https://policies.google.com/terms" title="Google reCAPTCHA Terms of Service">Terms of Service</a> apply.</p>
              </Notice>

              {
                s.postRequest?.hasError && <Notice backgroundColor={ColorPalette.red}>
                  <p>An error occurred while submitting your request. Please try again later, or <a href={`mailto:info@axondivision.com?subject=Service Enquiry&body=${s.emailBody}`} title="Write us an email">click here to send your enquiry as an email</a> instead. Your message content will be copied across automatically!</p>
                </Notice>
              }

              {
                s.recaptchaError && <Notice backgroundColor={ColorPalette.red}>
                  <p>Hmm... we couldn't verify that you are a human. Please try again shortly.</p>
                </Notice>
              }

              <CTAButton
                title="Submit"
                isLoading={s.isSubmitting}
                onClick={handleSubmit}
                Label="Submit"
                LoadingLabel="Submitting..."
                gaEvent={{ event: 'contact-form-submission' }}
                doNotAnimate
                disabled={ s.isSubmitting || s.succeeded }
              />

            </div>

          </footer>

        </form>

      </PageSection>

      { s.succeeded && <Redirect to="/thank-you" /> }

    </div>
  )} />

}

export default ContactForm;