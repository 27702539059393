import { action, observable } from "mobx";
import { UIController } from "../controllers/ui.controller";
import { isBrowser, isBuildTime } from "../env";
import { ActionConfig } from "../types/app.types";
import { sortRandomly } from "./array.utils";
import { loadScript } from "./dom.utils";
import { log } from "./loggers.utils";
import { getRandomInteger } from "./random.utils";

const anyWindow = isBrowser ? window as any : null;
const apiKey = process.env.RECAPTCHA_ID;

const protectedHostnames = [
  'trackster.com',
  'www.trackster.com',
  'trackster.eu.ngrok.io',
]

const privateState = observable({
  currentHostnameIsProtected: isBrowser ? protectedHostnames.includes(window.location.hostname) : false,
  shouldByPassRecaptcha: isBrowser ? (window.location.hostname === 'localhost' || !apiKey) : true,
})

export const checkErrorForRecaptchaFailure = action((error: Error | string) => {
  const string = JSON.stringify(error);
  if (string.toLowerCase().includes('recaptcha')) {
    privateState.shouldByPassRecaptcha = true;
    log('Next submission will bypass recaptcha and use fallback');
  }
})

export const fallbackValidator = (UI: UIController) => new Promise<string>(async (resolve, reject) => {
  const a = getRandomInteger(1,6);
  const b = getRandomInteger(a,9);
  const sum = a + b;
  const checkAnswer = (value: number) => {
    if (value === sum) resolve('ALGEBRA_TEST_PASSED');
    else reject('Spam validation failed.')
  }
  const actions: ActionConfig[] = sortRandomly([
    {
      resolve: sum + '',
      beforeResolve: () => checkAnswer(sum),
    },
    {
      resolve: Math.round(sum * 2.3) + '',
      beforeResolve: () => checkAnswer(Math.round(sum * 2.3)),
    },
    {
      resolve: Math.round(sum / 1.5) + '',
      beforeResolve: () => checkAnswer(Math.round(sum / 1.5)),
    },
  ])
  UI.presentDialog({
    Heading: `Let's play the "Are You a Human" game!`,
    Body: `Which number is the sum of ${a} and ${b}?`,
    actions,
  })
})

export function asyncGetRecaptchaToken(action: string = 'submit', UI: UIController) {
  return new Promise<string | null>(async (resolve, reject) => {
    if (isBuildTime) {
      resolve('UNEXPECTED_RECAPTCHA_CALL_IN_BUILD_TIME');
      return;
    }
    if (!privateState.currentHostnameIsProtected) {
      log('Current hostname is not protected by recaptcha.')
      privateState.shouldByPassRecaptcha = true;
    }
    if (privateState.shouldByPassRecaptcha) {
      try {
        const fallbackValidatorResult = await fallbackValidator(UI);
        resolve(fallbackValidatorResult);
      } catch (e) {
        reject(e);
      } finally {
        return;
      }
    }
    try {
      await loadScript(`https://www.google.com/recaptcha/api.js?render=${apiKey}`);
    } catch(e) {
      checkErrorForRecaptchaFailure('recaptcha');
      reject('Failed to load Google reCAPTCHA. Please try again.')
      return;
    }
    const { grecaptcha } = anyWindow;
    log('sending recaptcha request...');
    let timeout: any;
    try {
      grecaptcha.ready(() => {
        timeout = setTimeout(() => {
          reject('Google reCAPTCHA timed out.')
        }, 6180);
        try {
          grecaptcha.execute(
            apiKey,
            { action }
          ).then((token: string) => {
            resolve(token);
            log('recaptcha request success.');
            clearTimeout(timeout);
          })
        } catch(e) {
          reject(e);
          clearTimeout(timeout);
        }
      })
    } catch (e) {
      reject(e);
      clearTimeout(timeout);
    }
  })
}