import { Observer } from "mobx-react-lite"
import * as React from "react"
import ContactForm from "../components/ContactForm/ContactForm"
import DefaultPageHeader from "../components/DefaultPageHeader/DefaultPageHeader"
import PageTemplate from "../components/PageTemplate/PageTemplate"
import useSiteMetadata from "../hooks/useSiteMetadata.hook"
import { PageComponent } from "../types/gatsby.types"
import { ColorPalette } from "../utils/colors.utils"
import './contact.scss'

const PageContact: PageComponent = props => {

  const { keywords } = useSiteMetadata();
  
  return <Observer children={() => (
    
    <PageTemplate
      {...props} // always include
      className="PageContact"
      title="Contact"
      description="Contact AxonDivision."
      keywords={['contact', ...keywords]}
      backgroundColor={ColorPalette.white}
      foregroundColor={ColorPalette.dark}
    >
      <DefaultPageHeader
        id="ContactPageHeader"
        title="Contact"
      >
      </DefaultPageHeader>

      <ContactForm />

    </PageTemplate>
    
  )} />
}

export default PageContact
