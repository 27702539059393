import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import { AnimatedLink } from '../AnimatedLink/AnimatedLink';

type RedirectProps = {
  to: string,
}

const Redirect: React.FC<RedirectProps> = props => {
  const ref = useObservableRef();
  useOnMount(() => ref.current?.click());
  return <Observer children={() => (
    <AnimatedLink to={props.to} title="" ref={ref}/>
  )} />
}

export default Redirect;