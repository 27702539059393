import { action, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { getRandomNumericString } from '../../utils/random.utils';
import './BaseTextArea.scss';

type BaseTextAreaProps<F = AnyObject> = {
  className?: string,
  name?: string,
  type?: HTMLTextAreaElement['type'],
  Label?: ReactNode,
  form?: F,
  field?: StringKeyOf<F>,
  getter?: () => any,
  setter?: (v: any) => void,
  autoComplete?: HTMLTextAreaElement['autocomplete'],
  autoFocus?: HTMLTextAreaElement['autofocus'],
  autoCapitalize?: HTMLTextAreaElement['autocapitalize'],
  required?: boolean,
  optional?: boolean,
  rows?: HTMLTextAreaElement['rows']
}

const BaseTextArea = <F extends {} = AnyObject>(props: React.PropsWithChildren<BaseTextAreaProps<F>>) => {
  const p = useProps(props);
  const s = useStore(() => ({
    get name() {
      return p.name ?? p.field ?? getRandomNumericString();
    },
    get value() {
      return p.getter ? p.getter() : (p.form && p.field) ? p.form[p.field] : null;
    },
    set value(v) {
      p.setter ? p.setter(v) : (p.form && p.field) ? p.form[p.field] = v : void null;
    },
    handleChange: action((e: React.FormEvent<HTMLTextAreaElement>) => {
      s.value = (e.target as HTMLTextAreaElement).value;
    }),
    hasFocus: false,
    handleFocus: action(() => { s.hasFocus = true }),
    handleBlur: action(() => { s.hasFocus = false }),
  }))
  const ref = useObservableRef<HTMLTextAreaElement>();
  useOnMount(() => {
    const setupAutoResize = () => {
      const el = ref.current!;
      el.setAttribute("style", "height:" + (el.scrollHeight) + "px;overflow-y:hidden;");
      el.addEventListener("input", () => {
        el.style.height = "auto";
        el.style.height = (el.scrollHeight) + "px";
      }, false);
    }
    return when(
      () => !!ref.current,
      setupAutoResize
    )
  })
  return <Observer children={() => (
    <div className={joinClassName('BaseTextArea', p.className, s.hasFocus && 'hasFocus')}>
      {p.Label && <label htmlFor={p.name}>
        {p.Label} 
        {p.required && <em> * Required</em>}
        {p.optional && <em> (Optional)</em>}
      </label>}
      <textarea
        name={p.name}
        id={p.name}
        value={s.value}
        onChange={s.handleChange}
        autoComplete={props.autoComplete}
        autoFocus={props.autoFocus}
        autoCapitalize={props.autoCapitalize}
        onFocus={s.handleFocus}
        onBlur={s.handleBlur}
        rows={props.rows}
        ref={ref}
      />
    </div>
  )} />
}

export default BaseTextArea;