import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { flow, observable, toJS } from "mobx";
import { reportError } from './loggers.utils';

const _AxiosInstance = axios.create({
  baseURL: process.env.API_URL,
  // withCredentials: true,
})

// const _AxiosMockAdaptorInstance = new AxiosMockAdaptor(_AxiosInstance, { delayResponse: 2000 });
// _AxiosMockAdaptorInstance.onPost('contact-form').reply(200, {
//   success: true,
// })

export type AxiosRequest<T> = {
  request: Promise<AxiosResponse<T>> | null,
  response: AxiosResponse<T> | null,
  error: AxiosError<T> | null,
  status: AxiosResponse<T>['status'] | undefined,
  statusText: AxiosResponse<T>['statusText'] | undefined,
  data: AxiosResponse<T>['data'] | undefined,
  hasResolved: boolean,
  hasError: boolean,
  state: 'resolved' | 'error' | 'pending' | 'null',
  isPending: boolean,
}

const _wrapAxiosMethod = (
  type: 'post' | 'get' | 'patch' | 'put' | 'delete',
  axiosInstance: AxiosInstance,
) => <T, P = AnyObject>(
  url: string,
  payload: P,
) => {
  const s: AxiosRequest<T> = observable({
    request: null as Promise<AxiosResponse<T>> | null,
    response: null as AxiosResponse<T> | null,
    error: null as AxiosError<T> | null,
    get status() {
      return s.response?.status;
    },
    get statusText() {
      return s.response?.statusText;
    },
    get data() {
      return s.response?.data;
    },
    get hasResolved() {
      return !!s.response;
    },
    get hasError() {
      return !!s.error;
    },
    get state() {
      return s.hasResolved ? 'resolved' : s.hasError ? 'error' : !!s.request ? 'pending' : 'null';
    },
    get isPending() {
      return s.state === 'pending';
    }
  })
  flow(function * () {
    try {
      s.request = axiosInstance[type]<T>(url, toJS(payload))
      s.response = yield s.request;
    } catch(e) {
      s.error = e as any;
      reportError(e as any);
    }
  })();
  return s;
}

export const makeGetRequest = _wrapAxiosMethod('get', _AxiosInstance);
export const makePostRequest = _wrapAxiosMethod('post', _AxiosInstance);
export const makePatchRequest = _wrapAxiosMethod('patch', _AxiosInstance);
export const makePutRequest = _wrapAxiosMethod('put', _AxiosInstance);
export const makeDeleteRequest = _wrapAxiosMethod('delete', _AxiosInstance);

export type GetRequest<T> = AxiosRequest<T>;
export type PostRequest<T> = AxiosRequest<T>;
export type PatchRequest<T> = AxiosRequest<T>;
export type PutRequest<T> = AxiosRequest<T>;
export type DeleteRequest<T> = AxiosRequest<T>;
