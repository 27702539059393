import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import './BaseCheckbox.scss';

type BaseCheckboxProps<T extends AnyObject> = {
  className?: string,
  form: T,
  field: StringKeyOf<T>,
  name?: string,
  Label: ReactNode,
}

const BaseCheckbox = <T extends AnyObject>(props: React.PropsWithChildren<BaseCheckboxProps<T>>) => {
  const p = useProps(props);
  const s = useStore(() => ({
    get id() {
      return `BaseCheckbox--${p.name ?? p.field}`
    },
    get value() {
      return p.form[p.field];
    },
    set value(v) {
      p.form[p.field] = v;
    },
    handleChange: action((e: React.FormEvent<HTMLInputElement>) => {
      s.value = (e.target as HTMLInputElement).checked as any;
    }),
    hasFocus: false,
    handleFocus: action(() => { s.hasFocus = true }),
    handleBlur: action(() => { s.hasFocus = false }),
  }));
  return <Observer children={() => (
    <div className={joinClassName('BaseCheckbox', p.className, s.hasFocus && 'hasFocus', s.value && 'isSelected')}>
      <input
        id={s.id}
        type="checkbox"
        checked={s.value}
        onChange={s.handleChange}
        onFocus={s.handleFocus}
        onBlur={s.handleBlur}
      />
      <label htmlFor={s.id} className="BaseCheckboxLabel">
        <span className="BaseCheckboxLabelMarker"></span>
        <span className="BaseCheckboxLabelContent">{p.Label}</span>
      </label>
    </div>
  )} />
}

export default BaseCheckbox;